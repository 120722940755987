import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.es"

const IndexEN = ({ location, data }) => {
  return (
    <>
      <Metatags
        title="Multilógica"
        description="Proveedor de maquinaria para colchones"
        url="https://newsite.multilogica.ind.br/es/sobre-nosotros"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchón, máquina de coser, mammut, beckmann sew"
        language="es"
      />
      <NavBar location={location["pathname"]} />
      <div className="w-full">
        <GatsbyImage
          image={getImage(data.bannerAbout)}
          backgroundColor={false}
          alt="mattress machines"
        />
      </div>
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-20" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-white text-4xl font-semibold m-4">
              Sobre nosotros
            </div>
          </div>
          <div className="h-20" />
        </div>
      </div>
      <div className="h-0 md:h-8" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 place-self-center mr-12">
            <div className="text-mi-blue text-xl m-4">
              Fundada en 1990 con énfasis en electrónica y automatización
              industrial, Multilógica viene proveyendo servicios de desarrollo,
              reparación y automatización de máquinas industriales en diversas
              áreas, que van desde la industria del vidrio, farmacéutica y
              principalmente la industria de colchones, abarcando el comercio de
              maquinaria y repuestos
            </div>
          </div>
          <div className="col-span-1">
            <GatsbyImage
              image={getImage(data.about1)}
              backgroundColor={false}
              alt="machine maintenance"
            />
          </div>
        </div>
        <div className="h-0 md:h-8" />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 place-self-center mr-12">
            <div className="text-mi-blue text-xl m-4">
              Guiados por principios éticos y morales, nuestro objetivo es la
              excelencia en el servicio, aportando las máquinas más conocidas
              del mercado, proveyendo el mejor servicio y conectando a los
              fabricantes con el cliente final de forma transparente y eficaz,
              tocando la vanguardia tecnológica en máquinas de alta
              productividad.
            </div>
          </div>
          <div className="col-span-1">
            <GatsbyImage
              image={getImage(data.about2)}
              backgroundColor={false}
              alt="machine maintenance"
            />
          </div>
        </div>
        <div className="h-0 md:h-8" />
      </div>
      <div className="bg-mi-gray">
        <div className="h-20" />
        <div className="mx-auto text-center">
          <div className="text-mi-blue text-2xl font-semibold">
            Una historia basada en la experiencia y la confianza mutua con el
            cliente.
          </div>
        </div>
        <div className="h-20" />
      </div>
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="h-0 md:h-8" />
        <div className="max-w-4xl  mx-auto">
          <div className="text-mi-blue text-xl  font-bold m-4">
            Compromiso y Confianza
          </div>
          <div className="text-mi-blue text-xl  font-semibold m-4">
            Cumplimos con nuestros compromisos y asumimos la responsabilidad de
            nuestro desempeño en todas nuestras decisiones y acciones.
          </div>
          <div className="text-mi-blue text-xl  font-bold m-4">
            Experiencia y competencia
          </div>
          <div className="text-mi-blue text-xl  font-semibold m-4">
            Nuestra misión está profundamente integrada con la excelencia:
            colaboración, análisis y desarrollo de soluciones a medida.
          </div>
          <div className="text-mi-blue text-xl  font-bold m-4">
            Integridad y Ética
          </div>
          <div className="text-mi-blue text-xl  font-semibold m-4">
            Creemos en la honestidad, la ética y la integridad, por eso hacemos
            una contribución decisiva a nuestro entorno con el objetivo de
            impactar positivamente en la sociedad.
          </div>
        </div>
        <div className="h-20" />
      </div>
      <Footer lang="en" />
    </>
  )
}

export default IndexEN

export const aboutEN = graphql`
  query {
    bannerAbout: file(relativePath: { eq: "about-us/Banner_quem_somos.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
    about1: file(relativePath: { eq: "about-us/Galeria_SobreNos_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
    about2: file(relativePath: { eq: "about-us/Galeria_SobreNos_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
  }
`
